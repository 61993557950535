<template>
	<section v-background="value.section_bckgr.path" class="section-wrapper section cta-buss-to-buss">
		<base-container>
			<div class="inner-wrapper">
				<figure class="img-wrapper">
					<cms-image
						class="img"
						:src="value.image"
						position="top"
						:base="{width: 405, height: 180, position: 'center'}"
						:sm="{width: 305, height: 280, position: 'center'}"
						:md="{width: 405, height: 180, position: 'center'}"
						:lg="{width: 705, height: 1042}"
						:xl="{width: 705, height: 839}"
						layout
					/>
				</figure>
				<div class="section-content">
					<div class="slide-title-wrapper">
						<base-svg name="b2b_frame"/>
						<client-only>
							<cms-text
								:props="{variant: 'slider', color: 'secondary', tag: 'h1'}"
								:value="`${prefix}.section_title`"
								class="slide-title"
							/>
							<cms-text
								:props="{variant: 'slider-subheading', color: 'primary'}"
								:value="`${prefix}.subtitle`"
								class="slide-subtitle"
							/>
						</client-only>
					</div>
					<div class="section-description">
						<client-only>
							<cms-text
								:value="`${prefix}.desc`"
								:props="{variant: 'p', color: 'black'}"
								class="slide-desc"
							/>
						</client-only>
					</div>
					<div class="buttons">
						<div class="scroll-down" @click="scrollToNext">
							<base-font marginReset color="primary">Dowiedz się więcej</base-font>
							<base-svg class="scroll-down-svg" name="scroll_down_b2b"/>
						</div>
						<base-button :title="value.primary_btn_title" type="secondary" to="contact" icon="phone">Umów się na wizytę</base-button>
					</div>
				</div>
			</div>
		</base-container>
	</section>
</template>

<script>
import ClientOnly from 'vue-client-only'

export default {
	components: {
		ClientOnly
	},
	props: {
		prefix: String,
		value: Object
	},
	methods: {
		scrollToNext () {
			const alreadyScrolled = window.scrollY
			const nextSection = document.querySelector('.cta-buss-to-buss').nextSibling.offsetTop + 28 // 28 - height of slider arrow over the next section
			const headerHeight = document.querySelector('.navbar-wrapper').offsetHeight
			window.scrollBy({ top: nextSection - alreadyScrolled - headerHeight + window.screenTop, behavior: 'smooth' })
		}
	}
}
</script>
<style lang="scss" scoped>

@keyframes scrollDownMove {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0);
	}
}

.section {
	background-size: cover;

	@include media-breakpoint-up(xl) {
		background-repeat: no-repeat;
		background-position: right;
		background-size: contain;
	}

	&.section-wrapper {
		padding-bottom: 4rem;

		@include media-breakpoint-up(lg) {
			@media screen and (min-height: 750px) {
				padding-bottom: 0;
			}
		}

		@include media-breakpoint-up(xl) {
			padding-bottom: 0rem;
		}
	}
}

.img-wrapper {
	overflow: hidden;
	width: 100vw;
	position: relative;
	left: 50%;
	transform: translateX(-100%);
	margin: 0 0 4rem 50vw;
	@include media-breakpoint-up(lg) {
		width: 515px;
		position: relative;
		left: 0;
		margin: 0;
		transform: translateX(-26%);
		margin-bottom: 0;
	}
	@include media-breakpoint-up(xl) {
		width: auto;
		transform: none;
		margin: 0;
		left: -380px;
		overflow: visible;
	}

	@include media-breakpoint-up(xxl) {
		left: -560px;
		@media screen and (max-height: 800px) {
			left: -160px;
		}
	}

	@media (min-width: 2500px) {
		width: 400px;
		left: -585px;
	}

	.img {
		position: relative;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			width: .5rem;
			height: 100%;
			background-color: $secondary;
			display: none;

			@include media-breakpoint-up(xl) {
				display: block;
			}
		}

		@include media-breakpoint-up(xl) {
			display: inline-block !important;
		}

		::v-deep img {
			width: 100%;
			height: 180px;
			object-fit: cover;
			display: block;
			@include media-breakpoint-up(md) {
				height: 300px;
			}
			@include media-breakpoint-up(lg) {
				width: auto;
				height: auto;
				position: absolute;
				z-index: -1;
				width: 100%;
			}

			@include media-breakpoint-up(xl) {
				position: initial;
				z-index: initial;
				width: auto;
			}

			@include media-breakpoint-up(xxl) {
				height: 100vh;
			}
			@media (min-width: 2500px) {
				width: 1050px;
				height: 1150px;
			}
		}

		::v-deep picture {
			display: block;
		}
	}
}

.inner-wrapper {
	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: 420px 510px;
		margin-bottom: -10px;
	}
	@include media-breakpoint-up(xl) {
		padding-right: 75px;
		grid-template-columns: 420px 640px;
		gap: 6rem;
	}
	@include media-breakpoint-up(xxl) {
		gap: 145px;
		grid-template-columns: 420px auto;
	}
}

.slide-title-wrapper {
	position: relative;
	margin-bottom: 3rem;
	padding-bottom: 2rem;
	margin-left: 1rem;
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	margin: 1rem auto 2rem auto;

	@include media-breakpoint-landscape {
		margin-top: 3rem;
		margin-bottom: 1rem;
	}
	@include media-breakpoint-up(xl) {
		align-items: center;
		justify-content: center;
		margin-top: 0;
		padding-bottom: 5rem;
		margin-bottom: 5rem;
	}

	.slide-title {
		@include media-breakpoint-up(xxl) {
			font-size: 78px;
		}
	}

	.svg-icon {
		g path {
			fill: black;
		}

		position: absolute;
		top: -2rem;
		left: 0;
		right: 0;
		transform: scale(0.8);
		@include media-breakpoint-up(xl) {
			transform: scale(1);
			top: -5.6rem;
		}
		@include media-breakpoint-up(xxl) {
			width: 80%;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}
}

.section-content {
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include media-breakpoint-up(lg) {
		padding: 8rem 0;
	}

	.buttons {
		flex-direction: column-reverse;

		@include  media-breakpoint-up(xl){
			flex-direction: row;
		}
	}
}

.section-description {
	@include media-breakpoint-up(xl) {
		padding: 0 3rem;
	}

	::v-deep .slide-desc {
		text-align: center;

		strong {
			color: $primary;
		}
	}
}

.buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem 5rem;
	margin-top: 3rem;

	@include media-breakpoint-up(xl) {
		flex-direction: row;
		align-items: center;
		gap: 0rem 5rem;

	}

	@include media-breakpoint-up(xl) {
		margin-top: 6rem;
		gap: 0 12rem;
	}

	@include media-breakpoint-up(xxl) {
		margin-top: 6rem;
		gap: 0 12rem;
	}
}

.scroll-down {
	display: flex;
	align-items: center;
	flex-direction: column;
	cursor: pointer;

	.scroll-down-svg {
		animation: scrollDownMove 1s linear infinite paused;
	}

	@include media-breakpoint-up(xl) {
		&:hover {
			.scroll-down-svg {
				animation-play-state: running;
			}
		}
	}

	&-svg {
		width: 15px;
		margin-top: 5px;
	}
}
</style>
